<script setup>
import { ref, onBeforeMount, watch } from "vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import c3api from "../../c3api";
import { useToast } from "vue-toastification";
import { saveGridState, loadGridState, resetGridColState, resetGridFilterState } from "@/utils/agGridUtils";
import { useThemeStore } from "@/stores/themeStore";
import { useRouter } from "vue-router";
import BinDeleteCellRenderer from "../../components/bins/BinDeleteCellRenderer.vue";
import debounce from "lodash/debounce";
import useBins from "../../hooks/useBins";
import fileDownload from "js-file-download";

const { bins, fetchBins } = useBins();

let showAll = ref(false);


const themeStore = useThemeStore();
const loadCount = ref(0);
let gridApi;
const toast = useToast();
const GRID_SAVE_KEY = "bins";
const router = useRouter();

const warehouses = ref([]);
const warehouseIds = ref([]);

const BINS_EXTERNAL_FILTERS_KEY = "bins-external-filter";

const BINS_URL = `/bins`;



const reloadExternalFilters = function () {
  const savedExternalFilters = window.localStorage.getItem(BINS_EXTERNAL_FILTERS_KEY);
  if (savedExternalFilters) {
    let filters = JSON.parse(savedExternalFilters);
    if (typeof filters.warehouseIds == "undefined") filters.warehouseIds = { 0: 1 };
    warehouseIds.value = filters.warehouseIds;
    return filters;
  } else {
    return {};
  }
};


const onGridReady = (params) => {
  gridApi = params.api;
  reloadExternalFilters();
  loadGridState(GRID_SAVE_KEY, gridApi, colDefs.value);
};

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

watch(showAll, (showAll) => {
  gridApi.refreshServerSide();
});

const buildQueryParams = function (params, csv = false) {

  let whIds = warehouseIds.value;

  if (whIds.length < 1) whIds = [1];

  const urlParams = {
    warehouses: whIds,
    sortModel:
      params.request.sortModel.length > 0
        ? params.request.sortModel
        : [{ colId: "id", sort: "desc" }],
    filterModel: params.request.filterModel,
    startRow: params.request.startRow,
    endRow: params.request.endRow,
    batch_size: 100,
    limit: 400,
  };


  const filterParams = {};
  if (params.request.filterModel) {
    for (let [k, v] of Object.entries(params.request.filterModel)) {
      if (advancedFilterKeys.includes(k)) {
        if (v.filterType === "set") {
          filterParams[k] = {
            filter: v.values,
            type: v.filterType,
          };
        } else {
          filterParams[k] = v;
        }
      } else {
        urlParams[k] = v.filter || v.dateFrom?.substring(0, 10);
      }
    }
  }

  // Do not fetch deleted records.
  if (!showAll.value) {
    filterParams.deleted_at = {
      filter: null,
      filterType: "text",
      type: "equals"
    };
  }
  

  urlParams.filter = filterParams;

  if (typeof params.request.startRow !== "undefined") {
    urlParams.start_row = params.request.startRow;
    urlParams.limit = params.request.endRow - params.request.startRow;
  }
  if (csv === true) {
    urlParams.format = "csv";
  }

  return urlParams;
};

const onStateUpdated = function (params) {
  saveGridState(GRID_SAVE_KEY, gridApi);
};

const resetGridFiltersSettings = () => {
  resetGridFilterState(GRID_SAVE_KEY, gridApi);
  router.go();
};

const resetGridColSettings = () => {
  resetGridColState(GRID_SAVE_KEY, gridApi);
  router.go();
};

onBeforeMount(async () => {
  let pWarehouses = c3api.get("/warehouses").then((warehousesResponse) => {
    console.log("warehousesResponse:");
    console.log(warehousesResponse.data?.data);
    if (warehousesResponse.data.data) {
      warehouses.value = warehousesResponse.data.data;
      if (warehouseIds.value.length <= 0) {
        warehouseIds.value.push(warehouses.value[0].id);
      }
    }
  });

  await pWarehouses;
});

const saveFiltersToLocalStorage = function () {
  const filters = {};
  if (typeof warehouseIds.value != "undefined") filters.warehouseIds = warehouseIds.value;
  window.localStorage.setItem(BINS_EXTERNAL_FILTERS_KEY, JSON.stringify(filters));
};

const filtersChanged = function () {
  gridApi.onFilterChanged();
  saveFiltersToLocalStorage();
};

const debouncedFilterChanged = debounce(() => {
  filtersChanged();
}, 800);

watch(warehouseIds, (warehouseIds) => {
  debouncedFilterChanged();
});

const connectDatasource = {
  getRows: async (params) => {
    loadCount.value++;
    try {
      const urlParams = buildQueryParams(params);
      gridApi.showLoadingOverlay();
      const response = await fetchBins(urlParams);
      console.log("Response Data:", response.data);

      // Using params.success to pass the data to the grid
      params.success({
        rowData: response,
        rowCount: response.total_count,
      });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.data.join(". "));
      params.fail();
    } finally {
      loadCount.value--;
      if (loadCount.value <= 0) {
        gridApi.hideOverlay();
      }
    }
  },
  maxConcurrentDatasourceRequests: 1,
};

const advancedFilterKeys = ["id", "warehouse.nickname", "name"];

const defaultColDef = ref({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  suppressMenu: true,
  resizable: true,
  width: 120,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
});

const gridOptions = {
  rowModelType: "serverSide",
  serverSideDatasource: connectDatasource,
  rowSelection: "multiple",
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
  onStateChanged: () => {
    saveGridState(GRID_SAVE_KEY, gridApi);
  },
};

const colDefs = ref([
  {
    field: "id",
    headerName: "ID",
    cellDataType: "text",
    width: 150,
    checkboxSelection: true,
  },
  {
    field: "name",
    headerName: "Name",
    cellDataType: "text",
    width: 135,
  },
  {
    field: "holds_processable_damage",
    headerName: "Holds Damages",
    cellDataType: "boolean",
    width: 135,
  },
  {
    field: "holds_destructable_damage",
    headerName: "Holds Destructables",
    cellDataType: "boolean",
    width: 135,
  },
  {
    field: "not_inventory",
    headerName: "Is Inventory",
    cellDataType: "boolean",
    valueGetter: (params) => {
      return !params.data.not_inventory;
    },
    width: 135,
  },
  {
    field: "warehouse.nickname",
    headerName: "Warehouse",
    cellDataType: "text",
    filter: true,
    filterParams: {
      values: async params => {
        try {
          const response = await c3api.get("/warehouses");
          params.success(response.data?.data?.map(elem => elem.nickname));
        } catch (error) {
          console.error(error);
          toast.error(error.response.data.data.join(". "));
        }
      }
    },
    width: 135,
  },
  {
    field: "pallet_count_cache",
    headerName: "Pallet Count",
    cellDataType: "float",
    width: 135,
  },
  {
    field: "case_count_cache",
    headerName: "Case Count",
    cellDataType: "float",
    width: 135,
  },
  {
    field: "action",
    filter: null,
    headerName: "",
    sortable: false,
    colId: "action",
    cellRenderer: BinDeleteCellRenderer,
    width: 135,
  }
]);


const openBinDetailView = (event) => {
  if (event.data.id) {
    gridApi.showLoadingOverlay();
    window.location = `/bins/${event.data.id}`;
  } else {
    const errMsg = "Id has not been set correctly for this row";
    toast.error(errMsg);
    console.log(errMsg);
    console.log(event);
  }
}

const exportBinsAsCSV = async () => {

  loadCount.value++;
  gridApi.showLoadingOverlay();

  const filterModel = gridApi.getFilterModel();
  let sortModel = gridApi.getColumnState().filter((column) => column.sort);
  sortModel = sortModel.map((item) => ({ colId: item.colId, sort: item.sort }));

  const params = {
    request: {
      filterModel: filterModel,
      sortModel: sortModel
    }
  };

  const urlParams = buildQueryParams(params);

  try {
    const response = await c3api.get(`bins/csv`, { params: urlParams });
    const csv_data = response.data;
    const filename =
      response.headers["content-disposition"].match(/filename="?([^"]+)"?/)[1] ||
      "bins_export.csv";
    fileDownload(csv_data, filename);
  } catch (error) {
    console.error(error);
    toast.error(error);
  } finally {
    loadCount.value--;
    if (loadCount.value <= 0) {
      gridApi.hideOverlay();
    }
  }
};

</script>

<template>

  <div class="d-flex justify-end mr-4">
    <div>
      <v-chip-group v-model="warehouseIds" multiple column mandatory>
        <v-chip small filter outlined :value="w.id" v-for="w in warehouses" :key="w.id">
          {{ w.nickname }}
        </v-chip>
      </v-chip-group>
    </div>
  </div>

  <div class="justify-end d-flex mb-4 mr-2">

    <v-btn v-if="!showAll" class="ml-4 mt-2" color="primary" @click="showAll = !showAll">
      Show All
    </v-btn>

    <v-btn v-if="showAll" class="ml-4 mt-2" color="primary" @click="showAll = !showAll">
      Show Active
    </v-btn>

    <v-btn class="ml-2 mt-2" color="secondary" @click="exportBinsAsCSV()">
      Export CSV
    </v-btn>

    <v-btn class="ml-2 mt-2" @click="resetGridFiltersSettings()">
      RESET FILTERS
    </v-btn>

    <v-btn class="mr-4 ml-2 mt-2" @click="resetGridColSettings()">
      RESET COLUMNS
    </v-btn>
  </div>

  <div class="h-100 ml-4 mr-4">
    <AgGridVue class="h-100" :class="themeStore.agTheme" :columnDefs="colDefs" :gridOptions="gridOptions"
      :defaultColDef="defaultColDef" @grid-ready="onGridReady" @state-updated="onStateUpdated"
      @rowDoubleClicked="openBinDetailView">
    </AgGridVue>
  </div>

</template>