<script setup>
import { useToast } from "vue-toastification";
import { ref } from "vue";
import WarningDialog from "../../components/common/WarningDialog.vue";
import useBins from "../../hooks/useBins";
const { deleteBin } = useBins();
import { useRouter } from "vue-router";

const props = defineProps({
    params: Object,
});

const WARNING_TITLE = "Delete Confirmation"

const payload = {
    message: "Are you sure to delete this bin?",
    color: "red",
    data: props.params
}

const toast = useToast();
const router = useRouter()
const dialog = ref(false);

const deleteInvoice = async (removeBin) => {
    
    dialog.value = false;

    if (removeBin) {
        let params = props.params;
        const binId = params.data.id;
        const url = `/bins/${binId}`;

        await deleteBin(url)
            .then((response) => {
                dialog.value = false;
                params.api.refreshServerSide();
                toast.success("Bin has been successfully deleted.");
            })
            .catch((error) => {
                toast.error(error.response?.data.data.join(". "));
            });
    }
};

const handleDialogActions = () => {
    dialog.value = true
};

const handleEditAction = () => {
    const binId = props.params.data.id;
    router.push(`/bins/${binId}/edit`)
}
</script>

<template>

    <WarningDialog v-if="dialog" :callback="deleteInvoice" :msg="payload" :title="WARNING_TITLE" />

    <div class="d-flex justify-space-around">
        <v-btn icon="mdi-delete" variant="text" @click="handleDialogActions()"></v-btn>
        <v-btn icon="mdi-pencil" variant="text" @click="handleEditAction()"></v-btn>
    </div>
</template>
